import React from "react";

const ProjectCard = (props) => {
  const handleClick = () => {
    window.open(props.url, "_blank", "noopener,noreferrer");
  };

  const cardStyle = {
    backgroundImage: `url(${props.bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  // Adjusted overlay style for gradient
  const overlayStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 5,
    background: "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1))", // Dark to transparent gradient
  };

  return (
    <div>
      <div onClick={handleClick} style={{ position: "relative" }}>
        {" "}
        {/* Make this div relative */}
        <div
          style={cardStyle}
          className="cursor-pointer min-h-[250px] max-w-[370px] lg:max-w-full rounded-lg text-white p-4 flex flex-col justify-between leading-normal hover:shadow-2xl duration-500 ease-in-out"
        >
          {/* Overlay div */}
          <div style={overlayStyle}></div>

          {/* Content needs to be positioned relative or higher than the overlay */}
          <div className="relative z-10 font-ibmMono"></div>
          <div className="flex items-center relative z-10">
            <div className="flex text-sm mb-2">
              {props.types.map((item, index) => (
                <div
                  key={index}
                  id={item}
                  className="flex-initial py-1 px-4 w-auto text-center rounded-full font-ibmMono font-bold uppercase text-black bg-[#A6FD5B] mr-1 text-xs"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="text-2xl mb-2 font-ibmMono font-semibold leading-7 uppercase mt-3">
        {props.title}
      </div>
      <p className="font-ibmMono font-light text-sm">{props.about}</p>
    </div>
  );
};

export default ProjectCard;

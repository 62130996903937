import React, { Component } from "react";
import Slider from "react-slick";
import Myphoto1 from "../assets/Mypic1.png";
import Myphoto2 from "../assets/Mypic2.png";
import Myphoto3 from "../assets/Mypic3.png";
import Myphoto4 from "../assets/Mypic4.png";
import Myphoto5 from "../assets/Mypic5.png";
import Myphoto6 from "../assets/Mypic6.png";
import Myphoto7 from "../assets/Mypic7.png";
import Myphoto8 from "../assets/Mypic8.png";

import "../slick.css";
import "../slick-theme.css";

export default class SwipeToSlide extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 5,
      arrows: false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="text-white pt-10 pb-4 h-auto relative">
        <Slider {...settings}>
          <div className="">
            <img src={Myphoto1} alt="/" />
          </div>
          <div className="">
            <img src={Myphoto2} alt="/" />
          </div>
          <div>
            <img src={Myphoto3} alt="/" />
          </div>
          <div>
            <img src={Myphoto4} alt="/" />
          </div>
          <div>
            <img src={Myphoto5} alt="/" />
          </div>
          <div>
            <img src={Myphoto6} alt="/" />
          </div>
          <div>
            <img src={Myphoto7} alt="/" />
          </div>
          <div>
            <img src={Myphoto8} alt="/" />
          </div>
        </Slider>
        <p className="font-ibmMono m-4">Swipe to view more pictures >></p>
      </div>
    );
  }
}

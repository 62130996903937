import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { BrowserRouter } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import MoblieLogo from "../assets/JuanAnimation-blue.gif";
import resume from "../assets/Resume.pdf"; // Adjust the path as per your folder structure

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      // Change this value to adjust when the navbar appears
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      <div
        id="Nav"
        className={`flex fixed mx-auto top-0 left-0 right-0 z-50 bg-neutral-0/80 backdrop-blur py-3 justify-between items-center h-24 px-3 lg:px-10 text-white transition-opacity duration-500 ease-in-out ${
          showNavbar ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        {/* <img src={Logo} alt="/"/> */}
        <h1
          id=""
          className="w-full font-ibmMono font-bold text-3xl text-white "
        >
          JUAN<span className="text-[#A6FD5B]">.</span>GINEZ
        </h1>
        <ul className="hidden font-ibmMono font-bold md:flex  pr-4">
          <NavHashLink to="#Hero" smooth className="p-4 hover:text-[#A6FD5B]">
            Home
          </NavHashLink>
          <NavHashLink
            to="#Projects"
            smooth
            className="p-4 hover:text-[#A6FD5B]"
          >
            Projects
          </NavHashLink>
          <NavHashLink to="#About" smooth className="p-4 hover:text-[#A6FD5B]">
            About
          </NavHashLink>
          <NavHashLink
            to="#Contact"
            smooth
            className="p-4 hover:text-[#A6FD5B]"
          >
            Contact
          </NavHashLink>
          <a
            href={resume}
            download
            className="bg-[#221EEC] font-ibmMono font-bold text-center pt-4 py-3 w-[200px] text-white hover:bg-[#A6FD5B] hover:text-[#000000] transition ease-in duration-300"
          >
            Resume
          </a>
        </ul>

        <div onClick={handleNav} className="block md:hidden z-50">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        <div
          className={
            nav
              ? "fixed left-0 top-0 w-full h-full border-r bg-[#221EEC] border-r-gray-900 ease-in-out duration-500"
              : "fixed left-[-100%] uppercase bg-[#221EEC]"
          }
        >
          <img className="m-[1.85rem] ml-10" src={MoblieLogo} alt="/" />
          {/* <h1 className="w-full text-3xl font-ibmMono font-extrabold text-white m-[1.85rem] ml-10  uppercase">JUAN.GINEZ</h1> */}
          <ul className="sm:pt-28 pt-28 font-ibmMono font-extrabold sm:text-6xl text-5xl p-4 h-screen bg-[#221EEC]">
            <NavHashLink
              onClick={handleNav}
              to="#Hero"
              smooth
              className="flex p-8"
            >
              Home
            </NavHashLink>
            <NavHashLink
              onClick={handleNav}
              to="#About"
              smooth
              className="flex p-8"
            >
              About
            </NavHashLink>
            <NavHashLink
              onClick={handleNav}
              to="#Projects"
              smooth
              className="flex p-8"
            >
              Projects
            </NavHashLink>
            <NavHashLink
              onClick={handleNav}
              to="#Contact"
              smooth
              className="flex p-8"
            >
              Contact
            </NavHashLink>
          </ul>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default NavBar;

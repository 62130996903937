import React from 'react'
import About from './components/About'
import Footer from './components/Footer'
import Hero from './components/Hero'
import NavBar from './components/NavBar'
import Banner from './components/Banner'
import Projects from './components/Projects'
import SwipeToSlide from './components/SwipeToSlide'
import Contact from './components/Contact'


function App() {
  return (
    <div>
      <NavBar/>
      <Hero/>
      <Banner />
      <Projects />
      <SwipeToSlide/>
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

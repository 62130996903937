import React from "react";
import Marquee from "react-fast-marquee";

const Banner = () => {
  return (
    <Marquee
      gradient={false}
      pauseOnHover={true}
      speed={100}
      loop={0}
      className="w-full h-28 flex justify-center bg-[#A6FD5B] font-ibmMono font-bold uppercase text-4xl"
    >
      <p>
        {" "}
        Web Development * Human Centered Design * Creative Leadership * Process
        improvement * UI/UX * Reactjs * SharePoint * Power Apps *
      </p>
    </Marquee>
  );
};

export default Banner;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import Blinker from "../assets/blinker.svg";

const Footer = () => {
  const footerYear = new Date().getFullYear();

  return (
    <BrowserRouter>
      <div className="mx-auto pt-16 grid lg:grid-cols-2 gap-8 justify-center text-gray-300 bg-[#131314] px-20">
        <div>
          <h1
            id=""
            className="w-full font-ibmMono font-extrabold text-3xl text-white uppercase"
          >
            JUAN<span className="text-[#A6FD5B]">.</span>GINEZ{" "}
            <img
              className="w-[14px] blink pb-1 relative right-2 inline-block"
              src={Blinker}
              alt=""
            />
          </h1>
        </div>

        <div className="lg:col-span-1 flex justify-center xl:justify-end">
          <ul className="hidden font-ibmMono font-bold md:flex uppercase">
            <NavHashLink to="#Hero" smooth className="p-4 hover:text-[#A6FD5B]">
              HOME
            </NavHashLink>
            <NavHashLink
              to="#About"
              smooth
              className="p-4 hover:text-[#A6FD5B]"
            >
              ABOUT
            </NavHashLink>
            <NavHashLink
              to="#Projects"
              smooth
              className="p-4 hover:text-[#A6FD5B]"
            >
              PROJECTS
            </NavHashLink>
            <NavHashLink
              to="#Contact"
              smooth
              className="p-4 hover:text-[#A6FD5B]"
            >
              Contact Me
            </NavHashLink>
          </ul>
        </div>
      </div>
      <div className="mx-auto pb-16 gap-8 text-gray-300 bg-[#131314] px-20">
        <hr></hr>
        <p className="font-ibmMono italic text-center pt-8 text-[#959595]">
          Copyright &copy; {footerYear} Juanginez.com - All Rights Reserved.
        </p>
      </div>
    </BrowserRouter>
  );
};

export default Footer;

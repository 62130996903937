import React from "react";
import placeHolderImg from "../assets/placeholder-img.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const About = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
        },
      });
    }
    if (!inView) {
      animation.start({
        opacity: 0,
        y: 100,
      });
    }
  }, [inView, animation]);

  return (
    <div
      className="w-full pt-10 lg:pt-28 pb-28 px-3 text-white bg-[#221EEC]"
      id="About"
    >
      <div className="max-w-[1240px] mx-auto">
        <motion.div
          ref={ref}
          animate={animation}
          className="grid lg:grid-cols-2 gap-8 xl:gap-12 justify-center"
        >
          <div className="">
            <div className="font-ibmMono font-bold text-[5rem]">
              <h1 className="">About</h1>
            </div>
            <div className="flex text-sm mb-2">
              <div className="flex-initial p-1 w-20 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mr-1">
                FAMILY
              </div>
              <div className="flex-initial p-1 w-14 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mx-1">
                BBQ
              </div>
              <div className="flex-initial p-1 w-16 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mx-1">
                MEMES
              </div>
              <div className="flex-initial p-1 w-16 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mx-1">
                TACOS
              </div>
            </div>
            <div className="flex text-sm mb-2">
              <div className="flex-initial p-1 w-32 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mr-1">
                FRONT-END
              </div>
              <div className="flex-initial p-1 w-20 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mx-1">
                DESIGN
              </div>
              <div className="flex-initial p-1 w-32 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mx-1">
                PHOTOGRAPHY
              </div>
            </div>
            <div className="flex text-sm mb-10 xl:mb-26">
              <div className="flex-initial p-1 px-4 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mr-1">
                SMALL BUSINESS OWNERS
              </div>
              <div className="flex-initial p-1 w-20 text-center rounded-full font-ibmMono font-bold text-black bg-[#A6FD5B] mx-1">
                LEADER
              </div>
            </div>
            <div className="font-ibmMono italic">
              <p className="py-3">
                About Me: Native to San Antonio and currently residing in the
                lively neighborhood of Southtown, I am a dedicated husband of
                thirteen years and a proud father to two beautiful daughters.
                Alongside nurturing my family, I thrive as a small business
                owner and enjoy my self-proclaimed status as a pit master.
              </p>
              <p className="py-3">
                What Motiviates Me: As a first-generation Mexican-American
                immigrant, I am driven by a deep desire to honor my parents'
                sacrifices. My goal is to make a significant impact on the
                Westside of San Antonio, the underserved community where I spent
                my childhood. It was there that I discovered my passion for
                creativity and technology, I seek to give back and inspire
                others in the same way.
              </p>
              <p className="py-3">
                What I Do: I am passionate about leveraging technology to solve
                complex business problems. Front-end development, Microsoft
                Power Apps developer and SharePoint guru at USAA.
              </p>
            </div>
          </div>
          <motion.div ref={ref} animate={animation} className="">
            <img src={placeHolderImg} alt="" />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import Typed from "react-typed";
import Arrow from "../assets/arrow-down.svg";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="text-white leading-tight" id="Hero">
      <div className="flex flex-col justify-center max-w-3xl mt-[-20px] w-full h-screen mx-auto px-3 sm:px-5 relative">
        <div className="mt-96">
          <p className="text-white font-ibmMono lg:text-3xl text-[3rem] md:text-8xl sm:text-8xl font-bold sm:mb-0 uppercase">
            Hello, I'm
          </p>
          <Typed
            className="text-white font-ibmMono font-bold text-[3rem] lg:text-8xl md:text-8xl sm:text-8xl md:py-0 uppercase"
            strings={["Juan Ginez"]}
            typeSpeed={80}
          />
          <motion.p
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 120, opacity: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="font-ibmMono max-w-3xl md:text-1xl sm:text-1xl text-sm leading-7 md:py-6 pt-3"
          >
            My leadership style uniquely combines technical expertise, creative
            vision, and a sincere dedication to mentorship. At the forefront of
            USAA's digital transformation, I am committed to crafting delightful
            experiences and achieving excellence through innovation.
          </motion.p>
        </div>

        <div className=" lg:bottom-20 md:bottom-20 bounce absolute bottom-0 right-4">
          <img src={Arrow} alt="Juan Ginez Logo" />
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";

const Contact = () => {
  return (
    <div className="w-full py-24 px-3 text-white" id="Contact">
      <div className="max-w-[1240px] mx-auto">
        <div className="">
          <div className="">
            <div className="font-ibmMono font-bold text-center text-[5rem]">
              <h1 className="">Contact</h1>
            </div>
          </div>
          <div>
            <div className="w-full font-ibmMono">
              <form
                action="https://getform.io/f/646cf74d-8575-4bbd-a97e-47bdd5d645e7"
                method="POST"
                className="mt-6"
              >
                <div className="mb-6">
                  <label>
                    <span className="">Your name</span>
                    <input
                      type="text"
                      name="name"
                      className="bg-black border-white block w-full mt-2 px-4 py-2 
                            focus:outline-none focus:border-[#A6FD5B] focus:ring-1 focus:[#A6FD5B]  
                            invalid:border-red invalid:text-red"
                      placeholder="Steve Jobs"
                      required
                    />
                  </label>
                </div>
                <div className="mb-6">
                  <label>
                    <span className="">Email address</span>
                    <input
                      name="email"
                      type="email"
                      className="bg-black border-white block w-full mt-2 px-4 py-2 
                            focus:outline-none focus:border-[#A6FD5B] focus:ring-1 focus:[#A6FD5B]  
                            invalid:border-red invalid:text-red"
                      placeholder="SteveJobs@apple.com"
                      required
                    />
                    <input name="_gotcha" type="hidden" className="hidden" />
                  </label>
                </div>
                <div className="mb-6">
                  <label>
                    <span class="">Message</span>
                    <textarea
                      name="message"
                      className="bg-black border-white block w-full mt-2 px-4 py-2 
                            focus:outline-none focus:border-[#A6FD5B] focus:ring-1 focus:[#A6FD5B]  
                            invalid:border-red invalid:text-red"
                      rows="5"
                    ></textarea>
                  </label>
                </div>

                <div class="mb-6 flex">
                  <button
                    type="submit"
                    className="h-10 w-full xl:ml-auto xl:w-40 duration-500 px-5 font-ibmMono font-bold text-indigo-100 bg-[#221EEC] transition-colors  focus:shadow-outline hover:bg-[#a6fd5b]"
                  >
                    Send it!
                  </button>
                </div>
                <div></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import ProjectCard from "./ProjectCard";

import myImage1 from "../assets/site-bikes.jpeg";
import myImage2 from "../assets/Vida-Thumb.png";
import myImage3 from "../assets/github.png";
import myImage4 from "../assets/Jokes.png";
import myImage5 from "../assets/CodingBootcamp-13.jpg";

const Projects = () => {
  return (
    <div
      className="w-full pt-10 lg:pt-28 pb-28 px-3 text-white bg-[#221EEC]"
      id="Projects"
    >
      <div className="max-w-[1240px] mx-auto">
        <div className="font-ibmMono font-bold text-[4rem]">
          <h1 className="">Projects</h1>
        </div>
        <div className="font-ibmMono mb-6 text-sm leading-6">
          <p>
            In the heart of San Antonio's contemporary arts district, I work
            with the city's most talented creatives, making a significant
            citywide impact. Our work reflects the dynamic pulse of San Antonio
            and Austin's art scenes, embodying innovation and community
            engagement. Together, we've transformed local culture, proving that
            collaboration and passion can elevate the creative spirit of a city.
          </p>
        </div>
        <div className="grid lg:grid-cols-2 gap-8">
          <ProjectCard
            title="Turis Electric Bike Start-Up"
            about="Premium Electric Bike Tour Rentals Business in San Antonio, Tx. Turis electric bike rentals will unlock the city of San Antonio in a new way!"
            url="https://Turisbikes.com"
            types={["Branding", "Web Design", "Photography"]}
            bgImage={myImage1}
          />
          <ProjectCard
            title="Coding Bootcamp for inner-city Kids"
            about="Juan Ginez, a self-taught coder, now bridges the city's digital divide by leading a coding camp for underprivileged youth, drawing from his own journey of overcoming barriers to technology."
            url="https://www.youtube.com/watch?v=bu4bOSMTyIc"
            types={["Public Relations", "Communication"]}
            bgImage={myImage5}
          />
          <ProjectCard
            title="Vida Barbero Podcast"
            about="The Vida Barbero Podcast, hosted by Ray Thompson and produced by Juan Ginez, stood as the premier citywide barbershop podcast, spotlighting the remarkable talents and stories of San Antonio's finest barbers."
            url="https://www.youtube.com/@vidabarberopodcast845"
            types={["videography", "Social Media", "Branding"]}
            bgImage={myImage2}
          />
          <ProjectCard
            title="Dad Joke Generator"
            about="The Dad Joke Generator is a fun little app I created to keep my girls entertained and laughing. Whenever boredom strikes, I just fire it up for an instant dose of humor. Hope it brings a smile to your face too!"
            url="https://dad-jokes-app-alpha.vercel.app/"
            types={["React", "Api", "Dasiy UI"]}
            bgImage={myImage4}
          />
          <ProjectCard
            title="Github Finder"
            about="Leveraging the GitHub API, I developed a custom search engine that allows users to explore GitHub profiles and view detailed profile information."
            url="https://github-search-js2rsi07g-juanginez.vercel.app/home"
            types={["React", "API", "Tailwind", "Daisyui"]}
            bgImage={myImage3}
          />
        </div>
      </div>
    </div>
  );
};

export default Projects;
